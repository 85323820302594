// 3rd party
import React from "react"
import styled from "styled-components"

// components
import Link from "../general/link"
import BreadcrumbItem from "./breadcrumbItem"

// constants
import {colors} from "../../constants/css"

const Breadcrumb = ({uri, name, number}) => {
  const bullet = "&#8226;"

  return (
    <>
      <BreadcrumbItem dangerouslySetInnerHTML={{__html: bullet}} />
      <BreadcrumbItem noWrap={number > 2}>
        {uri ? (
          <Link uri={uri}>
            {name}
            {number > 2 && (
              <>
                {" "}
                <Number>{number - 1} more</Number>
              </>
            )}
          </Link>
        ) : (
          name
        )}
      </BreadcrumbItem>
    </>
  )
}

const Number = styled.span`
  background-color: ${colors.red};
  padding: 0 5px;
`

export default Breadcrumb
