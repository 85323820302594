import React from "react"
import styled from "styled-components"
import {colors} from "../../constants/css"
const ArrowInCircle = ({direction, color = colors.red}) => {
  return (
    <Svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 209.2079 209.2079"
      style={{enableBackground: "new 0 0 612 792"}}
      invert={direction === "left"}
    >
      <circle
        className="st0"
        cx="104.60395"
        cy="104.60395"
        r="96.099998"
        style={{fill: "#fff", stroke: color, strokeWidth: "17.0079", strokeMiterlimit: "10"}}
      />
      <path
        className="st1"
        style={{fill: color}}
        d="m 112.90394,49.503955 52.4,49.4 c 2.5,2.299995 2.5,6.299995 0.1,8.699995 l -50.2,50.2 c -3.8,3.8 -10.3,1.1 -10.3,-4.3 v -20.7 c 0,-3.3 -2.7,-6 -6.000004,-6 h -47.4 c -3.3,0 -6,-2.7 -6,-6 V 93.503955 c 0,-3.3 2.7,-6 6,-6 h 44.7 c 3.3,0 6.000004,-2.6 6.000004,-5.9 l 0.5,-27.6 c 0.1,-5.4 6.4,-8.1 10.2,-4.5 z"
      />
    </Svg>
  )
}

const Svg = styled.svg`
  height: 100%;
  transform: scaleX(${({invert}) => (invert ? -1 : 1)});
`

export default ArrowInCircle
