// 3rd party
import React from "react"
import styled, {css} from "styled-components"

// constants//util
import {sizes, transition} from "../../../constants/css"
import {getImage} from "../../../lib/dataManipulation"

// components
import WorkThumbContent from "./workThumbContent"

//functions
function getSizeFactor(index, base = 0.64) {
  let sizeFactor = Math.pow(base, index - 1)
  return sizeFactor
}
function getSize(index) {
  let sizeFactor = getSizeFactor(index)
  return index === 0 ? sizes.thumb : sizeFactor * sizes.thumb
}

function getOffset(index, spacing = 9) {
  let cum = 0
  if (index === 0) cum = -100
  for (let i = 1; i < index; i++) {
    cum += getSize(i) + spacing
  }
  return cum
}

function WorkThumb({index, item, onClick, side, isVisible}) {
  let size = getSize(index)
  let offset = getOffset(index)
  let image = getImage(item.image, "thumb-small")
  let isImage = item.type === "image"
  return (
    <Thumb {...{index, size, side, offset, isVisible}} onClick={() => onClick(index)}>
      <WorkThumbContent {...{item, size, image, isImage}} />
    </Thumb>
  )
}

const Thumb = styled.div`
  cursor: pointer;
  height: ${({size}) => size}px;
  width: ${({size}) => size}px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: ${transition.gallery}ms;
  opacity: ${({index, isVisible}) => (!isVisible ? 0 : index === 0 ? 0 : Math.pow(0.8, index))};
  ${({side}) =>
    side === "left" &&
    css`
      right: ${({offset}) => offset}px;
    `}
  ${({side}) =>
    side === "right" &&
    css`
      left: ${({offset}) => offset}px;
    `}
`

export default WorkThumb
