// 3rd party
import React from "react"

// components
import Breadcrumb from "./breadcrumb"

function BreadcrumbsMedium({medium, mediumParent, number}) {
  mediumParent = mediumParent || medium?.parent?.node
  return (
    <>
      {mediumParent && <Breadcrumb uri={mediumParent.uri} name={mediumParent.title} />}
      {medium && <Breadcrumb uri={medium.uri} name={medium.title} number={number} />}
    </>
  )
}

export default BreadcrumbsMedium
