// 3rd party
import React from "react"
import styled from "styled-components"

// components
import WpContent from "../general/wpContent"
import {splitText} from "../../lib/utils"
import Line from "./styled/line"
import TwoCol from "./styled/twoCol"
import Embed from "./embed"
import Scrollable from "../general/scrollable"
import {device} from "../../constants/css"

function Lyric({lyric, title, embed}) {
  let [firstHalf, secondHalf] = splitText(lyric)
  return (
    <Scrollable>
      <Wrapper>
        <Grid>
          {embed && (
            <EmbedWrapper>
              <Embed embed={embed} autoplay={false} isLarge={false} />
            </EmbedWrapper>
          )}
          <Text>
            <TwoCol>
              <div>
                <H2>{title} </H2>
                {firstHalf && <WpContent content={firstHalf} />}
              </div>
              <Line />
              <div>{secondHalf && <WpContent content={secondHalf} />}</div>
            </TwoCol>
          </Text>
        </Grid>
      </Wrapper>
    </Scrollable>
  )
}

const EmbedWrapper = styled.div`
  position: relative;
  height: 170px;
  width: 170px;
  grid-area: embed;
`

const Text = styled.div`
  grid-area: text;
  @media ${device.mobileL} {
    padding-top: 35px;
    width: 600px;
  }
`

const Grid = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-areas: "text" "embed";
  grid-row-gap: 30px;
  @media ${device.laptop} {
    grid-column-gap: 30px;
    grid-template-areas: "embed text";
  }
`

const H2 = styled.h2`
  @media ${device.mobileL} {
    position: absolute;
    top: 0;
    transform: translateY(calc(-100% - 15px));
  }
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`

export default Lyric
