import {getOffsets} from "./getOffsets"

export function getSpring(imageRef, dx, dy) {
  let [top, left, bottom, right] = getOffsets(imageRef /*wrapperRef*/ /*, transformerRef*/)
  //console.log(left, right, dx)
  //console.log(imageRef.current?.getBoundingClientRect().left, imageRef.current?.getBoundingClientRect().right)
  let stiffness = 1 / 20
  let ySpring = 1
  let xSpring = 1
  if (dy > 0) {
    ySpring = 1 + stiffness * Math.max(top, 0)
  } else if (dy < 0) {
    ySpring = 1 + stiffness * Math.max(bottom, 0)
  }
  if (dx > 0) {
    xSpring = 1 + stiffness * Math.max(left, 0)
  } else if (dx < 0) {
    xSpring = 1 + stiffness * Math.max(right, 0)
  }
  return [xSpring, ySpring]
}
