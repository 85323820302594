// 3rd party
import React, {useEffect, useContext} from "react"
import {graphql as gql} from "gatsby"
import queryString from "query-string"

// components
import Seo from "../components/general/seo"
import WorkSingle from "../components/work/workSingle"

// context
import {GlobalDispatchContext, GlobalStateContext} from "../context/GlobalContextProvider"
import {getNavItem} from "../lib/dataManipulation"

export default function Work({data, location}) {
  let {p: projectId, m: mediumId, t: navType} = queryString.parse(location.search)
  navType = ["medium", "project"].includes(navType) && navType

  // state
  const appDispatch = useContext(GlobalDispatchContext)
  const appState = useContext(GlobalStateContext)

  let medium = getNavItem(mediumId, "medium", appState.navData)
  let mediumParent = getNavItem(medium?.parent?.node.id, "medium", appState.navData)
  let project = getNavItem(projectId, "project", appState.navData)
  let projectParent = getNavItem(project?.parent?.node.id, "project", appState.navData)

  // data
  let {work} = data
  let mainProject = projectParent || project
  let mediumParentTitle = mediumParent?.medium?.singular ? " - " + mediumParent.medium.singular : ""
  let mediumTitle = medium?.medium?.singular ? " - " + medium.medium.singular : ""
  let mainProjectTitle = mainProject ? " - " + mainProject.title : ""

  // effect
  useEffect(() => {
    let attachedType = appState.navType === "medium" ? medium : project
    let attachedItems = attachedType?.attached?.edges
    appDispatch({type: "setAttached", value: attachedItems})
  }, [appDispatch, work, medium, project, appState.navType])

  useEffect(() => {
    let page = {
      main: {
        medium: medium?.parent ? medium.parent.node.id : medium?.id,
        project: mainProject?.id
      },
      sub: {medium: medium?.id, project: project?.id},
      currentId: work.id,
      template: "work"
    }
    appDispatch({type: "setPage", value: {type: navType, page: page}})
  }, [appDispatch, navType, medium, project, mainProject, work])

  return (
    <>
      <Seo title={work.title + mediumParentTitle + mediumTitle + mainProjectTitle} description={work.description.content} />
      <WorkSingle {...{work, project, mainProject, medium, mediumParent}} postType={appState.navType} />
    </>
  )
}

export const QUERY_WORK_BY_ID_PROJECT_BY_SLUG = gql`
  query WorkQuery($id: Int!) {
    work: wpWork(databaseId: {eq: $id}) {
      ...workFields
      ...extraContent
    }
  }
`
