import styled from "styled-components"

// util/ constants
import {colors, device, sizes} from "../../../constants/css"

export const Fallback = styled.div`
  border: 1px solid ${colors.white};
  left: 50%;
  bottom: 0;
  position: absolute;
  transform: translateX(-50%);

  width: min(calc(${({$aspectRatio}) => $aspectRatio} * (100vh - ${sizes.splashVerticalMargin.small}px)), 100%);
  width: min(calc(${({$aspectRatio}) => $aspectRatio} * (var(--vh, 1vh) * 100 - ${sizes.splashVerticalMargin.small}px)), 100%);
  height: min(calc(${({$aspectRatio}) => 1 / $aspectRatio} * (100vw - ${sizes.splashHorizontalMargin.small}px)), 100%);
  @media ${device.mobileL} {
    width: min(calc(${({$aspectRatio}) => $aspectRatio} * (100vh - ${sizes.splashVerticalMargin.large}px)), 100%);
    width: min(calc(${({$aspectRatio}) => $aspectRatio} * (var(--vh, 1vh) * 100 - ${sizes.splashVerticalMargin.large}px)), 100%);
    height: min(calc(${({$aspectRatio}) => 1 / $aspectRatio} * (100vw - ${sizes.splashHorizontalMargin.large}px)), 100%);
  }
  transition: 0.25s;
  opacity: ${({$isVisible}) => ($isVisible ? 1 : 0)};
`
