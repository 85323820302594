// 3rd party
import React, {useState, useContext, useRef, useEffect} from "react"
import styled, {css} from "styled-components"

// context/lib
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {getSpring} from "./utils/getSpring"
import {getCursor} from "./utils/getCursor"

// components
import SwipeImage from "./swipeImage"
import {colors} from "../../constants/css"
import {getMouseCoordinates, getTouchCoordinates} from "../../lib/utils"

function SwipeImageOuter(props) {
  let {image, isActive} = props
  let {handleNext, handlePrev, canSwipeLeft, canSwipeRight} = props
  let canSwipe = canSwipeLeft || canSwipeRight

  // state
  const appState = useContext(GlobalStateContext)

  // state
  let [notification, setNotification] = useState("")
  let [isDragging, setIsDragging] = useState(false)
  let [isSwiping, setIsSwiping] = useState(false)
  let [start, setStart] = useState({x: 0, y: 0})
  let [current, setCurrent] = useState({x: 0, y: 0})
  // https://www.html5rocks.com/en/mobile/touch/#toc-touchdev
  // https://www.google.com/search?q=javascript+detect+pinch+zoom&source=hp&ei=k4okYovSFZa8sAf14YSADw&iflsig=AHkkrS4AAAAAYiSYozpLVeNywoOEST0qZcmD3XgKvMhT&ved=0ahUKEwjL5_ubobH2AhUWHuwKHfUwAfAQ4dUDCAc&uact=5&oq=javascript+detect+pinch+zoom&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEIAEMgYIABAWEB5QAFgAYKUBaABwAHgAgAFJiAFJkgEBMZgBAKABAqABAQ&sclient=gws-wiz
  // https://dev.to/danburzo/pinch-me-i-m-zooming-gestures-in-the-dom-a0e
  // https://developer.mozilla.org/en-US/docs/Web/API/Pointer_events/Pinch_zoom_gestures
  let [initialTouches, setInitialTouches] = useState()
  let [scale, setScale] = useState(1)
  let [transform, setTransform] = useState({x: 0, y: 0})
  let [isContained, setIsContained] = useState(true)
  let canToggle = appState.navIsCollapsed

  let debug = false
  let cursor = getCursor(isDragging, canToggle, isActive, isContained)

  // ref
  let imageRef = useRef()

  // touch event handlers
  function handleTouchStart(e) {
    if (isDragging) return
    let coordinates = getTouchCoordinates(e)
    if (isContained) {
      handleSwipeStart(coordinates)
    } else {
      // not contained
      if (e.touches.length === 2) {
        initZoom(e)
      } else {
        handleDragStart(coordinates)
      }
    }
  }

  function initZoom(e) {
    setInitialTouches(e.touches)
    debug && console.log("initZoom")
    setNotification("initZoom " + e.touches.length)
    setIsDragging(true)
  }

  function handleTouchMove(e) {
    let coordinates = getTouchCoordinates(e)
    if (isSwiping) {
      handleSwipeMove(coordinates)
    } else if (isDragging) {
      if (e.touches.length === 2) {
        handleZoom(e)
      } else {
        handleDragMove(coordinates, e)
      }
    }
  }

  function handleZoom(e) {
    debug && setNotification("handleZoom " + scale)
    if (initialTouches) {
      let newScale = Math.sqrt(distance(e.touches) / distance(initialTouches)) * scale
      newScale = Math.max(newScale, 0.5)
      newScale = Math.min(newScale, 2.5)
      debug && console.log(newScale)
      setScale(newScale)
    }
    setInitialTouches(e.touches)
  }

  function handleTouchEnd(e) {
    if (isSwiping) {
      handleSwipeEnd(e)
    } else if (isDragging) {
      handleDragEnd(e)
    }
  }

  // mouse event handlers
  function handleMouseStart(e) {
    if (isContained || isDragging) return
    debug && console.log("handleMouseStart", isDragging)
    let coordinates = getMouseCoordinates(e)
    handleDragStart(coordinates)
  }

  function handleMouseMove(e) {
    if (!isDragging || isContained) return
    e.preventDefault()
    debug && console.log("handleMouseMove")
    let coordinates = getMouseCoordinates(e)
    handleDragMove(coordinates)
  }

  // drag
  function handleDragStart(coordinates) {
    debug && console.log("handleDragStart")
    debug && setNotification("handleDragStart " + scale)
    setIsDragging(true)
    setStart(coordinates)
    setCurrent(coordinates)
    setInitialTouches()
  }

  function handleSwipeStart(coordinates) {
    debug && console.log("handleSwipeStart")
    debug && setNotification("handleSwipeStart " + scale)
    if (!canSwipe) return
    setIsSwiping(true)
    setStart(coordinates)
    setCurrent(coordinates)
  }

  function handleDragMove(coordinates) {
    debug && console.log("handleDragMove")
    debug && setNotification("handleDragMove " + scale)
    setInitialTouches()
    let dx = coordinates.x - current.x
    let dy = coordinates.y - current.y
    let [xSpring, ySpring] = getSpring(imageRef, dx, dy)
    dx = dx / xSpring
    dy = dy / ySpring
    setTransform({x: transform.x + dx, y: transform.y + dy})
    setCurrent(coordinates)
  }

  function handleSwipeMove(coordinates) {
    debug && console.log("handleSwipeMove")
    debug && setNotification("handleSwipeMove " + scale)
    let dx = coordinates.x - current.x
    let dy = coordinates.y - current.y

    if (isContained && canSwipe) {
      dy = 0

      // prevent swipe in not allowed direction
      if (!canSwipeLeft && dx > 0) dx = 0
      if (!canSwipeRight && dx < 0) dx = 0

      // handle swipe beyond threshold
      let threshold = 70
      if (transform.x < -threshold) {
        handleNext()
        return
      } else if (transform.x > threshold) {
        handlePrev()
        return
      }
    }
    setTransform({x: transform.x + dx, y: transform.y + dy})
    setCurrent(coordinates)
  }

  function handleDragEnd(e) {
    debug && console.log("handleDragEnd")
    debug && setNotification("hDragEnd " + scale + ", cx:" + current.x + " sc:" + start.x + " " + initialTouches)
    e.preventDefault()

    let deltaX = current.x - start.x
    let deltaY = current.y - start.y

    if (deltaX === 0 && deltaY === 0 && !!!initialTouches) {
      debug && setNotification("handleDragEnd was click")
      setIsContained(!isContained)
      setStart({x: 0, y: 0})
      setCurrent({x: 0, y: 0})
    }
    setInitialTouches()
    setIsDragging(false)
  }

  function handleSwipeEnd(e) {
    setTransform({x: 0, y: 0})
    setIsSwiping(false)
  }

  useEffect(() => {
    setTransform({x: 0, y: 0})
    setScale(1)
  }, [isContained])

  return (
    <>
      <Transformer
        $isContained={isContained}
        style={{
          transform: `translate(calc(-50% + ${isContained ? 0 : 50 - image.focalPoint.x}% + ${transform.x}px), calc(-50% + ${
            isContained ? 0 : 50 - image.focalPoint.y
          }% + ${transform.y}px)) scale(${scale})`
        }}
        onMouseDown={handleMouseStart}
        onMouseMove={handleMouseMove}
        onMouseUp={handleDragEnd}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <Inner>
          <SwipeImage {...{cursor, isContained, setIsContained, transform, imageRef}} {...props} />
        </Inner>
      </Transformer>
      {!isContained && <Background />}
      {debug && <Notification>{notification}</Notification>}
    </>
  )
}

export default SwipeImageOuter

const Background = styled.div`
  background-color: ${colors.black};
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 101;
  top: 0;
  left: 0;
`

const coverStyles = css`
  z-index: 102;
`

const Transformer = styled.div`
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  position: ${({$isContained}) => ($isContained ? "absolute" : "fixed")};
  ${({$isContained}) => !$isContained && coverStyles}
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Notification = styled.div`
  position: absolute;
  top: 0px;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  z-index: 103;
  padding: 5px;
`

// functions
function distance(touches) {
  let [t1, t2] = touches
  let dx = t2.clientX - t1.clientX
  let dy = t2.clientY - t2.clientY
  return Math.sqrt(dx * dx + dy * dy)
}
