import styled, {css} from "styled-components"

// util/ constants
import {device, transition} from "../../../constants/css"

const containStyles = css`
  bottom: 0;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
  object-position: bottom;
  max-height: 100%;
`

const coverStyles = css`
  width: 200%;
  max-width: none;
  height: auto;
  @media ${device.tabletM} {
    width: 125%;
  }
`

export const StyledImage = styled.img`
  cursor: ${({$cursor}) => $cursor};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  touch-action: none;
  transition: opacity ${transition.gallery}ms;
  opacity: ${({$isVisible}) => ($isVisible ? 1 : 0)};
  ${({$isContained}) => $isContained && containStyles}
  ${({$isContained}) => !$isContained && coverStyles}
`
