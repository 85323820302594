// 3rd party
import React from "react"
import styled from "styled-components"

// constants
import {transition} from "../../../constants/css"

// components
import WorkThumb from "./workThumb"

function WorkThumbsLarge({leftImages, rightImages, items, imageNum, handlePrev, handleNext}) {
  let currentItem = items[imageNum]
  let {height, width} = (currentItem?.type === "image" && currentItem?.image?.mediaDetails) || {height: 1, width: 50}
  let aspectRatio = width / height
  let maxImageNums = 3
  let maxIndex = maxImageNums + 1 + 1

  return (
    <div className="from-tablet-m">
      <Left aspectRatio={aspectRatio}>
        <Inner>
          {leftImages.map((item, index) => {
            return (
              index <= maxIndex && (
                <WorkThumb {...{index, item}} isVisible={index <= maxImageNums} onClick={handlePrev} side="left" key={item.id} />
              )
            )
          })}
        </Inner>
      </Left>
      <Right aspectRatio={aspectRatio}>
        <Inner>
          {rightImages.map((item, index) => {
            return (
              index <= maxIndex && (
                <WorkThumb {...{index, item}} isVisible={index <= maxImageNums} onClick={handleNext} side="right" key={item.id} />
              )
            )
          })}
        </Inner>
      </Right>
    </div>
  )
}

const ThumbsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  transition: ${transition.gallery}ms;
`

const Left = styled(ThumbsWrapper)`
  right: min(calc(15px + 50% + 0.5 * ${({aspectRatio}) => aspectRatio} * (100vh - 100px)), calc(15px + 100%));
`

const Right = styled(ThumbsWrapper)`
  left: min(calc(15px + 50% + 0.5 * ${({aspectRatio}) => aspectRatio} * (100vh - 100px)), calc(15px + 100%));
`

const Inner = styled.div`
  position: relative;
  height: 70px;
  width: 500px;
`

export default WorkThumbsLarge
