// 3rd party
import React from "react"
import {TransitionGroup, CSSTransition} from "react-transition-group"
import styled from "styled-components"

// util/ constants
import {device, transition} from "../../constants/css"

// components
import SwipeImageOuter from "./swipeImageOuter"

function TransitioningImage({image, ...props}) {
  return (
    <StyledBackgroundSection>
      <TransitionGroup component={null}>
        {[image.sourceUrl].map(src => {
          return (
            <CSSTransition timeout={transition.gallery} key={src} appear={true} unmountOnExit classNames="fade">
              <SwipeImageOuter {...{image, ...props}} />
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </StyledBackgroundSection>
  )
}

const StyledBackgroundSection = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  @media ${device.tabletM} {
    margin-bottom: 0px;
  }
  touch-action: none;
  position: relative;
`

export default TransitioningImage
