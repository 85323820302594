// 3rd party
import React, {useState} from "react"
import styled from "styled-components"

// components
import WorkThumbContent from "./workThumbContent"

// constants
import {sizes, transition} from "../../../constants/css"
import {getImage} from "../../../lib/dataManipulation"
import {getTouchCoordinates} from "../../../lib/utils"

function WorkThumbsMobile({images, handleChange, items, indexOfCurrentInAround, imageNum}) {
  let [currentX, setCurrentX] = useState(0)
  let [transformX, setTransformX] = useState(0)
  let [isDragging, setIsDragging] = useState(false)

  function handleThumbClick(index) {
    handleChange(index)
    setTransformX(0)
  }

  function handleTouchStart(e) {
    if (isDragging) return
    setIsDragging(true)
    let coordinates = getTouchCoordinates(e)
    setCurrentX(coordinates.x)
  }

  function handleTouchMove(e) {
    let coordinates = getTouchCoordinates(e)
    let dx = coordinates.x - currentX
    setTransformX(transformX + dx)
    setCurrentX(coordinates.x)
    document.body.classList.add("no-overflow")
  }

  function handleTouchEnd(e) {
    setCurrentX(0)
    setIsDragging(false)
    document.body.classList.remove("no-overflow")
  }

  return (
    <div className="until-tablet-m" aria-hidden={true}>
      <SmallThumbsWrapper onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
        {images.map((item, i) => {
          let isCurrent = item === items[imageNum]
          let index = i - indexOfCurrentInAround
          //console.log(i, index)
          //let image = item.type !== "lyric" && getImage(item.image, "thumb-small")
          let image = getImage(item.image, "thumb-small")
          return (
            <SmallThumb
              {...{index, isCurrent}}
              key={item.id}
              onClick={() => handleThumbClick(index)}
              $transformX={transformX}
              $isDragging={isDragging}
            >
              <WorkThumbContent image={image} isImage={item.type === "image"} size={sizes.thumb} />
            </SmallThumb>
          )
        })}
      </SmallThumbsWrapper>
    </div>
  )
}

const SmallThumb = styled.button.attrs(({index, $transformX}) => ({
  style: {
    left: `calc(50% + ${index * sizes.thumb + 15 * (index - 1)}px + ${$transformX}px)`
  }
}))`
  position: absolute;
  transition: ${({$isDragging}) => ($isDragging ? 0 : transition.gallery)}ms;
  width: ${sizes.thumb}px;
  height: ${sizes.thumb}px;
  opacity: ${({isCurrent}) => (isCurrent ? 0.2 : 1)};
  transform: translateX(-50%);
`

const SmallThumbsWrapper = styled.div`
  display: flex;
`

export default WorkThumbsMobile
