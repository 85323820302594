import React, {useState} from "react"
import styled from "styled-components"
import {colors, device} from "../../../constants/css"
import ArrowInCircle from "../../icons/arrowInCirle"

function WorkContentNav({
  activeItem,
  setActiveItem,
  types,
  extras,
  isModalOpen,
  setIsModalOpen,
  handleNext,
  handlePrev,
  items,
  imageNum,
  isActive
}) {
  const [isHovered, setIsHovered] = useState()
  return (
    <Nav>
      {imageNum > 0 && (
        <ArrowWrapperLeft onClick={() => handlePrev()} isVisible={isActive}>
          <ArrowInCircle direction="left" />
        </ArrowWrapperLeft>
      )}
      {types.map((type, i) => {
        let isActive = activeItem === type
        let isLarge = type === "description"
        return (
          !!extras[type].length && (
            <Icon
              key={i}
              isActive={isActive || !isModalOpen}
              onClick={() => {
                setIsModalOpen(isActive ? !isModalOpen : true)
                setActiveItem(type)
              }}
              onMouseEnter={() => setIsHovered(i)}
              onMouseLeave={() => setIsHovered()}
            >
              <Text isActive={isHovered === i && !isActive} isLarge={isLarge}>
                {type.replace(/-/g, " ")}
              </Text>
              <Img src={`/icons/${type}.svg`} isLarge={isLarge} />
            </Icon>
          )
        )
      })}
      {imageNum + 1 < items.length && (
        <ArrowWrapperRight onClick={() => handleNext()} isVisible={isActive}>
          <ArrowInCircle />
        </ArrowWrapperRight>
      )}
    </Nav>
  )
}

const ArrowWrapper = styled.button`
  width: 21px;
  bottom: 8px;
  left: calc(50%);
  position: absolute;
  transition: 0.25s;
  @media ${device.tabletM} {
    opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
    visibility: ${({isVisible}) => (isVisible ? "visible" : "hidden")};
  }
`

const ArrowWrapperLeft = styled(ArrowWrapper)`
  transform: translateX(-50%) translateX(-100px);
`

const ArrowWrapperRight = styled(ArrowWrapper)`
  transform: translateX(-50%) translateX(100px);
`

const Nav = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  z-index: 5;
`
const Text = styled.div`
  opacity: ${({isActive}) => (isActive ? 1 : 0)};
  visibility: ${({isActive}) => (isActive ? "visible" : "hidden")};
  &:hover {
    opacity: 1;
    visibility: visible;
  }
  font-size: 0.625rem;
  transition: 0.3s;
  z-index: -1;
  position: absolute;
  transform: translate(${({isLarge}) => (isLarge ? -3 : -1)}px, 0) rotate(-90deg);
  background-color: ${colors.background};
  color: ${colors.white};
  padding: 8px 24px 8px 60px;
  transform-origin: left bottom;
  white-space: nowrap;
  overflow: hidden;
  bottom: 0;
  left: 100%;
`
const Icon = styled.button`
  position: relative;
  opacity: ${({isActive}) => (isActive ? 1 : 0.4)};
  &:hover {
    opacity: 1;
  }
  transition: 0.3s;
  color: ${colors.white};
  * {
    fill: ${colors.white};
  }

  //width: 36px;
  //padding: 10px 6px;
`
const Img = styled.img`
  height: ${({isLarge}) => (isLarge ? 35 : 25)}px;
  margin: 0 3px;
`
export default WorkContentNav
